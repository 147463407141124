<template>
  <Row class="page3">
    <Col style="width: 26%; height: 95.5%">
      <div class="left">
        <span class="title"
          ><span class="title-text">{{ picsTitle }}</span></span
        >
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="left1" style="height: 30%; padding-top: 7%">
          <div style="height: 80%; display: flex">
            <div style="height: 100%; width: 48%">
              <pie ref="chart3" id="pie_1"></pie>
            </div>
            <div style="height: 100%; width: 48%">
              <pie ref="chart4" id="pie_3"></pie>
            </div>
          </div>
        </div>
        <div class="left1" style="height: 43%">
          <red-pocket ref="redPocket"></red-pocket>
        </div>
        <div style="width: 100%; height: 24%">
          <div style="width: 100%; height: 100%">
            <channel-bar
              id="left_5"
              :data="schoolData1"
              title="覆盖学校数量"
              ref="channelBar2"
            ></channel-bar>
            <channel-bar
              id="left_5"
              :data="schoolData2"
              ref="channelBar3"
            ></channel-bar>
          </div>
        </div>
      </div>
    </Col>

    <Col style="width: 48%; padding: 0 1%; height: 70%">
      <div class="description">
        <span class="title"
          ><span class="title-text">{{ picsTitle }}</span></span
        >
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="classifyExplain">{{ classifyExplain }}</span>
          <span v-else style="text-align: center; font-size: 18px"
            >暂无简介......</span
          >
        </div>
      </div>
      <div class="center-top">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width: 26%; height: 108%; margin-top: 8.2%">
      <div class="right-1">
        <div class="right1-1">
          <span class="title"
            ><span class="title-text">{{ picsTitle }}</span></span
          >
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <webcasts-risk ref="webcastsRisk"></webcasts-risk>
        </div>
      </div>
      <div class="right-2">
        <div class="right-pics">
          <span class="title"
            ><span class="title-text">{{ picsTitle }}</span></span
          >
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="false"></workPics>
        </div>
        <div class="right-pics" style="margin-left: 3%">
          <span class="title"><span class="title-text">{{ photoTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="false"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div
        v-for="(item, index) in classify"
        :key="index"
        @click="chooseNav(item, index)"
        :class="index == chooseIndex ? 'on_workname' : 'workname'"
      >
        {{ item.classifyName }}
      </div>
    </div>
  </Row>
</template>

<script>
import { getDataApi, totalDataApi, getHonorData } from "@/api/dataList";
// import {handleOldNewArrFn} from "@/lib/oldNewArr";

const chinaMap = () => import("./components/chinaMap");
const channelBar = () => import("./components/page3/channelBar");
// const distributionSolider = ()=> import('./components/page3/distributionSolider');
const pie = () => import("./components/pie");
const redPocket = () => import("./components/page3/redPocket");
const webcastsRisk = () => import("./components/page3/webcastsRisk");
// const deviceSafeRisk = ()=> import('./components/page3/deviceSafeRisk');
const workPics = () => import("./components/page2/workPictures");
const honorPics = () => import("./components/page2/HonoraryPics.vue");
// const doubleRing = ()=> import('./components/page3/doubleRing');
// const hotWords =() => import('./components/page3/hotWords');

export default {
  name: "page3",
  components: {
    chinaMap,
    channelBar,
    // distributionSolider,
    pie,
    redPocket,
    webcastsRisk,
    // deviceSafeRisk,
    workPics, //项目图片
    honorPics, //荣誉图片
    // doubleRing,
    // hotWords
  },
  data() {
    return {
      //tabNav子菜单
      classify: [],
      photoTitle:'湖湘公益汇',
      chooseIndex: 0,
      picsTitle: "", //右下角图片标题
      sportArr: [], //饼图活动人次数组
      serviceArr: [], //饼图服务人次数组
      schoolList: [], //覆盖学校数量
      schoolName: [], //覆盖学校数量标题
      //地图展示项目数据
      resdata: [],
      //项目图片数组
      workPicsList: [],
      schoolData1: [
        {
          subtitle: "长沙市",
          top: "29%",
          data: { name: "长沙", value: 80, color: "#0E4CFF" },
        },
        {
          subtitle: "株洲市",
          top: "56%",
          data: { name: "株洲", value: 48, color: "#FE9900" },
        },
        {
          subtitle: "湘潭市",
          top: "84%",
          data: { name: "湘潭", value: 50, color: "#7F05FD" },
        },
      ],
      schoolData2: [
        {
          subtitle: "衡阳市",
          top: "29%",
          data: { name: "衡阳", value: 46, color: "#087a83" },
        },
        {
          subtitle: "常德市",
          top: "56%",
          data: { name: "常德", value: 40, color: "#c52d5a" },
        },
        {
          subtitle: "益阳市",
          top: "84%",
          data: { name: "益阳", value: 36, color: "#326002" },
        },
      ],
      pieData1: {
        // 饼图数据1
        title: "活动场次",
        color: "#2C7BFE",
        data: [
          {
            value: 76,
            name: "长沙",
            itemStyle: {
              color: "#1456FE",
            },
          },
          {
            value: 17,
            name: "株洲",
            itemStyle: {
              color: "#00CCFF",
            },
          },
          {
            value: 15,
            name: "湘潭",
            itemStyle: {
              color: "#142AFE",
            },
          },
          {
            value: 10,
            name: "衡阳",
            itemStyle: {
              color: "#1493FE",
            },
          },
          {
            value: 28,
            name: "常德",
            itemStyle: {
              color: "#252448",
            },
          },
          {
            value: 14,
            name: "益阳",
            itemStyle: {
              color: "#165c62",
            },
          },
          {
            value: 22,
            name: "娄底",
            itemStyle: {
              color: "#3d3c8f",
            },
          },
          {
            value: 15,
            name: "郴州",
            itemStyle: {
              color: "#31cddc",
            },
          },
          {
            value: 12,
            name: "永州",
            itemStyle: {
              color: "#8483d7",
            },
          },
          {
            value: 10,
            name: "怀化",
            itemStyle: {
              color: "#5ae1ee",
            },
          },
          {
            value: 10,
            name: "湘西",
            itemStyle: {
              color: "#4d8a8e",
            },
          },
          {
            value: 10,
            name: "张家界",
            itemStyle: {
              color: "#8e6b8b",
            },
          },
          {
            value: 10,
            name: "岳阳",
            itemStyle: {
              color: "#e0a4ac",
            },
          },

          {
            value: 10,
            name: "邵阳",
            itemStyle: {
              color: "#709670",
            },
          },
        ],
      },
      pieData2: {
        // 饼图数据1
        title: "服务人次",
        color: "#2C7BFE",

        data: [
          {
            value: 76,
            name: "长沙",
            itemStyle: {
              color: "#1456FE",
            },
          },
          {
            value: 17,
            name: "株洲",
            itemStyle: {
              color: "#00CCFF",
            },
          },
          {
            value: 15,
            name: "湘潭",
            itemStyle: {
              color: "#142AFE",
            },
          },
          {
            value: 10,
            name: "衡阳",
            itemStyle: {
              color: "#1493FE",
            },
          },
          {
            value: 28,
            name: "常德",
            itemStyle: {
              color: "#252448",
            },
          },
          {
            value: 14,
            name: "益阳",
            itemStyle: {
              color: "#165c62",
            },
          },
          {
            value: 22,
            name: "娄底",
            itemStyle: {
              color: "#3d3c8f",
            },
          },
          {
            value: 15,
            name: "郴州",
            itemStyle: {
              color: "#31cddc",
            },
          },
          {
            value: 12,
            name: "永州",
            itemStyle: {
              color: "#8483d7",
            },
          },
          {
            value: 10,
            name: "怀化",
            itemStyle: {
              color: "#5ae1ee",
            },
          },
          {
            value: 10,
            name: "湘西",
            itemStyle: {
              color: "#4d8a8e",
            },
          },
          {
            value: 10,
            name: "张家界",
            itemStyle: {
              color: "#8e6b8b",
            },
          },
          {
            value: 10,
            name: "岳阳",
            itemStyle: {
              color: "#e0a4ac",
            },
          },

          {
            value: 10,
            name: "邵阳",
            itemStyle: {
              color: "#709670",
            },
          },
        ],
      },
      videoShow: false,
      resizeFn: null,
      classifyExplain: "",
      vedioShow: false,
    };
  },
  created() {
    // this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      totalDataApi(16).then((res) => {
        this.classify = res.data.classify;
        console.log(this.classify, "classify");
        this.chooseNav(res.data.classify[0], 0);
      });
    },
    //  点击子菜单选择
    async chooseNav(item, index) {
      // console.log(item,'--item00')
      const res = await getDataApi(item.classifyId);
      this.resdata = res.rows;
      this.chooseIndex = index;
      this.classifyExplain = item.classifyExplain;
      this.picsTitle = item.classifyName; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = item.img;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading = false;
      const HonRes = await getHonorData(item.classifyId);
      if (HonRes.code == 200) {
        console.log(HonRes);
        if (HonRes.data.switchOption == 1) {
          //荣誉图片
          this.$refs.honor.shows = 1;
          this.photoTitle = "荣誉图片";
          this.$refs.honor.videoUrl = "";
          this.$refs.honor.perfectPicsListL = [];
          this.$refs.honor.honoraryPicsList = item.honorImage;
        } else if (HonRes.data.switchOption == 2) {
          //精彩视频
          this.$refs.honor.shows = 2;
          if (HonRes.data.videoUrl) {
            HonRes.data.videoUrl = JSON.parse(HonRes.data.videoUrl);
          }
          this.photoTitle = HonRes.data.videoUrl[0].title||'精彩视频';
          console.log(this.photoTitle, "title");
          this.$refs.honor.honoraryPicsList = [];
          this.$refs.honor.perfectPicsListL = [];
          this.$refs.honor.videoUrl = HonRes.data.videoUrl[0].url;
        } else if (HonRes.data.switchOption == 3) {
          //精彩图片
          this.$refs.honor.shows = 3;
          if (HonRes.data.photoUrl) {
            HonRes.data.photoUrl = JSON.parse(HonRes.data.photoUrl);
          }
          this.photoTitle = "湖湘公益汇";
          this.$refs.honor.videoUrl = "";
          this.$refs.honor.honoraryPicsList = [];
          this.$refs.honor.perfectPicsListL = HonRes.data.photoUrl;
        }
      } else {
        this.$refs.honor.honoraryPicsList = [];
        this.$refs.honor.videoUrl = "";
        this.$refs.honor.perfectPicsListL = [];
      }
      // this.$refs.honor.honoraryPicsList = item.honorImage;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load = false; //加载中
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.sportArr = []; //活动场次
      this.serviceArr = []; //服务人次
      console.log(this.$refs.redPocket, "this.$refs.redPocket.data");
      this.$refs.redPocket.data = []; //志愿者人数
      this.$refs.redPocket.name = item.classifyName; //classifyName来判断显示志愿者人数还是活动人次
      this.schoolList = []; //覆盖学校量
      this.schoolName = []; //覆盖学校量城市名称
      this.$refs.webcastsRisk.socialData = []; //社会组织参与数
      this.$refs.webcastsRisk.moneyData = []; //投入款物
      for (var i = 0; i < this.resdata.length; i++) {
        // this.resdata[i].choiceIndex = index; //选择导航栏 把index对应传给choiceIndex 从而获取不同数据
        //社区儿童素养志愿者人数改成服务人次
        if (item.classifyName == "社区儿童素养") {
          this.$refs.redPocket.data.push(this.resdata[i].list[0].soprtcount); //服务人次
        } else {
          this.$refs.redPocket.data.push(
            this.resdata[i].list[0].volunteerCount
          ); //志愿者人数
        }
        this.sportArr.push(this.resdata[i].list[0].soprtcount); //活动场次
        this.serviceArr.push(this.resdata[i].list[0].serviceCount); //服务人次
        this.schoolList.push(this.resdata[i].list[0].schoolCount); //覆盖学校数量
        this.schoolName.push(this.resdata[i].name); //覆盖学校数量的城市名称
        this.$refs.webcastsRisk.socialData.push(this.resdata[i].list[0].attent); //社会组织参与数
        this.$refs.webcastsRisk.moneyData.push(this.resdata[i].list[0].money); //投入款物

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue =
          Number(this.resdata[i].list[0].attent) +
          Number(this.resdata[i].list[0].money) +
          Number(this.resdata[i].list[0].schoolCount) +
          Number(this.resdata[i].list[0].serviceCount) +
          Number(this.resdata[i].list[0].soprtcount) +
          Number(this.resdata[i].list[0].volunteerCount);
        this.resdata[i].value = totalValue;
      }

      // 覆盖学校量
      let schoolList1 = this.schoolList.slice(0, 3); //左边前三个城市的覆盖学校量
      let schoolName1 = this.schoolName.slice(0, 3); //左边前三个城市的覆盖学校量的城市名称
      let schoolList2 = this.schoolList.slice(3, 6); //右边第四个到第六个城市的覆盖学校量
      let schoolName2 = this.schoolName.slice(3, 6); //右边第四个到第六个城市的覆盖学校量的城市名称
      for (var j = 0; j < this.schoolData1.length; j++) {
        this.schoolData1[j].data.value = schoolList1[j];
        this.schoolData1[j].subtitle = schoolName1[j];
        this.schoolData1[j].data.name = schoolName1[j];
        this.schoolData2[j].data.value = schoolList2[j];
        this.schoolData2[j].subtitle = schoolName2[j];
        this.schoolData2[j].data.name = schoolName2[j];
      }

      // 循环饼图数组
      for (var k = 0; k < this.pieData1.data.length; k++) {
        this.pieData1.data[k].value = this.sportArr[k]; //活动场次
        this.pieData2.data[k].value = this.serviceArr[k]; //服务人次
      }
      this.$refs.chart3.data = this.pieData1; //活动场次
      this.$refs.chart4.data = this.pieData2; //服务人次
      this.$refs.channelBar2.data = this.schoolData1; //覆盖学校量(1-3)
      this.$refs.channelBar3.data = this.schoolData2; //覆盖学校量(4-6)
      this.$refs.channelBar2.setChart(); //刷新覆盖学校数据
      this.$refs.channelBar3.setChart(); //刷新覆盖学校数据
      this.$refs.chart4.setChart(); //刷新饼图数据
      this.$refs.chart3.setChart(); //刷新饼图数据
      this.$refs.redPocket.setChart(); //刷新志愿者人数数据
      this.$refs.webcastsRisk.setWebcasts(); //刷新社会组织参与数、投入款物数据

      this.$refs.chinaMap.seriesData = this.resdata;
      this.$refs.chinaMap.setMap(); //刷新地图数据
    },
  },
  mounted() {
    this.getData();
    this.resizeFn = this.$debounce(() => {
      this.$refs.chinaMap.setMap();
      this.$refs.channelBar2.setChart(); //刷新覆盖学校数据
      this.$refs.channelBar3.setChart(); //刷新覆盖学校数据
      this.$refs.chart4.setChart(); //刷新饼图数据
      this.$refs.chart3.setChart(); //刷新饼图数据
      this.$refs.redPocket.setChart(); //刷新志愿者人数数据
      this.$refs.webcastsRisk.setWebcasts(); //刷新社会组织参与数、投入款物数据
    }, 500);
    window.addEventListener("resize", this.resizeFn);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeFn);
  },
};
</script>

<style lang="less" scoped>
.page3 {
  height: 100%;
  width: 100%;
  padding: 8px 10px 20px;
  background: #03044a;
  overflow: hidden;
  position: relative;

  .tabNav {
    cursor: pointer;
    width: 100%;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 0;
    //justify-content: space-around;
    .workname {
      color: #fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right: 1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }

    .on_workname {
      color: #4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }

  .ivu-col {
    height: 100%;
    float: left;
  }

  .left,
  .right1-1,
  .right-pics,
  .center-bottom,
  .description {
    height: 100%;
    border: 1px solid #0d2451;
    position: relative;
    background: #151456;
    box-sizing: border-box;

    #left_5 {
      height: 100%;
      width: 45%;
      float: left;
    }

    #left_6 {
      height: 100%;
      width: 55%;
      float: left;
    }

    .circular {
      height: 100%;

      .canvas {
        height: 100%;
        width: 30%;
        float: left;

        .subtitle {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          height: 25px;
          padding: 10px 0;
          text-align: center;
        }

        .canvasList {
          height: calc(~"100% - 25px");
          text-align: center;
        }
      }
    }

    .left1 {
      border-bottom: 1px solid #0d2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6eddf1;
      border: 2px solid #6eddf1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102e;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }

  .right-pics {
    width: 50%;
  }

  .center-top {
    height: 115%;
  }

  .description {
    background: none !important;
    width: 158% !important;
    height: 24% !important;
    box-sizing: border-box;
    padding-bottom: 2%;
  }

  .describeList {
    text-indent: 3%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 1% auto;
    align-items: center;
    color: rgb(167, 198, 235);
    line-height: 27px;
    font-size: 15px;
    overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;

    span {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .describeList::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .describeList::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #a1b3dc;
  }

  .describeList::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: rgb(8, 25, 79);
  }

  .center-bottom {
    height: 40%;

    .bottom-radars {
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }

  .right-1 {
    height: 30%;

    .right1-1 {
      height: 92%;
    }
  }

  .right-2 {
    height: 40%;
    display: flex;
  }
}
</style>
